import React from "react"
import { Link } from "gatsby"

import "../styles/styles.scss";
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import SEO from "../components/seo"

import overviewGraphic from "../assets/overview-graphic.png";
import overviewGraphicMobile from "../assets/overview-graphic-mobile.png";
import overviewCardOne from "../assets/overview-icon-bars.svg"
import overviewCardTwo from "../assets/overview-icon-scatter.svg";
import overviewCardThree from "../assets/overview-icon-circle.svg"

import infoGraphicCircle from "../assets/info-graphic-circle.svg";
import infoGraphicRealCAC from "../assets/info-graphic-realcac.svg";

import lineGraph from "../assets/line-graph.svg";

const IndexPage = () => (
  <div className="homepage">
    {/* <GoogleTagManager /> */}
    <Navbar />
    <SEO title="DataHelm.com - Marketing Attribution that Makes Sense" />

    <section className="intro-section">
      <div className="intro-info">
        <h1 className="intro-info__heading">Marketing Attribution you can act on</h1>
        <h2 className="intro-info__subheading">Understand the value of every dollar spent and intelligently decide how to keep scaling profitably.</h2>
        <a href="#contact" className="intro-info__cta">Get started today!</a>
      </div>
    </section>

    <section className="overview-section" id="product">

      <div className="overview-illustration-side">
        <img className="overview-illustration overview-illustration--desktop" src={overviewGraphic} alt="overview-graphic"></img>
        <img className="overview-illustration overview-illustration--mobile" src={overviewGraphicMobile} alt="overview-graphic"></img>
      </div>

      <div className="overview-info-side">
        <div className="overview-cards">
          <div className="overview-card">
            <img className="overview-card-img" src={overviewCardOne} alt="bar-graph"></img>
            <div className="overview-card-info">
              <h3 className="overview-card-info__title overview-card-info__title--one">KPI Dashboards</h3>
              <p className="overview-card-info__content">Visualize all of your important data in one place.</p>
            </div>
          </div>
          <div className="overview-card">
            <img className="overview-card-img" src={overviewCardTwo} alt="scatter-plot"></img>
            <div className="overview-card-info">
              <h3 className="overview-card-info__title overview-card-info__title--two">Predictive Modeling</h3>
              <p className="overview-card-info__content">Predict future performance based on custom historical models.</p>
            </div>
          </div>
          <div className="overview-card">
            <img className="overview-card-img" src={overviewCardThree} alt="circle-graph"></img>
            <div className="overview-card-info">
              <h3 className="overview-card-info__title overview-card-info__title--three">Fully Integrated</h3>
              <p className="overview-card-info__content">We integrate with every marketing and sales platform.</p>
            </div>
          </div>
        </div>
      </div>

    </section>

    <section className="info-section" id="#features">

      <div className="info-block info-block--first">
        <div className="info-block-text info-block-text--first">
          <h2 className="info-block-text__heading info-block-text__heading--first">
          <span>Interpret the impact </span>of each dollar spent.
          </h2>
          <ul className="info-block-text__list info-block-text__list--first">
            <li className="info-block-text__bullet info-block-text__bullet--first">Determine your <span>RealCAC</span> -- the true cost of each customer acquisition.</li>
            <li className="info-block-text__bullet info-block-text__bullet--first">Draw insights from models and measure the impact of ad spending towards your bottom line.</li>
          </ul>
        </div>
        <div className="info-block-img info-block-img--first">
          <img src={infoGraphicCircle} alt=""/>
        </div>
      </div>

      <div className="info-block info-block--second">
        <div className="info-block-text info-block-text--second">
          <h2 className="info-block-text__heading info-block-text__heading--second">Get a handle on your <span>fully blended CAC.</span></h2>
          <ul className="info-block-text__list info-block-text__list--second">
            <li className="info-block-text__bullet info-block-text__bullet--second">Understand your <span>baseline</span> performance.</li>
            <li className="info-block-text__bullet info-block-text__bullet--second">Expect & incorporate <span>seasonality</span> into the decision making Process.</li>
            <li className="info-block-text__bullet info-block-text__bullet--second"><span>Filter out the noise</span> and ignore anomalies confidently.</li>
          </ul>
        </div>
        <div className="info-block-img info-block-img--second">
          <img src={infoGraphicRealCAC} alt=""/>
        </div>
      </div>

    </section>

    <section className="incrementality-section">
      <div className="incrementality-info">
        <h1 className="incrementality-info__heading">
          Incrementality Testing Made Easy
        </h1>
        <p className="incrementality-info__text">
        Once our historical models are built, running incrementality tests is simple:
        <ul className="incrementality-info__list">
          <li className="incrementality-info__list-item"><h4>01</h4><p>Define the test budget and period with you.</p></li>
          <li className="incrementality-info__list-item"><h4>02</h4><p>Start running the incremental marketing spend.</p></li>
          <li className="incrementality-info__list-item"><h4>03</h4><p>As the test is running we analyze performance, providing transparent details about the ongoing impact.</p></li>
          <li className="incrementality-info__list-item"><h4>04</h4><p>Provide a complete analysis afterwards with concrete action items on what can be improved.</p></li>
        </ul>
        </p>
      </div>
      <div className="incrementality-img">
        <img src={lineGraph} alt="line graph"/>
      </div>
    </section>

    <section className="about-section" id="about">
      <div className="about-info">
        <h2 className="about-info__heading">ABOUT US</h2>
        <p className="about-info__paragraph">
          DataHelm was started with the goal of simplifying marketing attribution in a way that aligns with how performance marketers work.
          Marketers are most often channel experts and need a simple north star to hit as their goal. This is why we came up with the <strong>RealCAC</strong>.
        </p>
        <p className="about-info__paragraph">
          <strong>RealCAC</strong> gives each channel expert a CAC target that is attainable and keeps blended CACs in line with profitability.
          This removes the need to consider "multi-touch attribution". Instead, each channel is optimized effectively, and can scale efficiently.
        </p>
      </div>
    </section>

    <section className="contact-section" id="contact">
      <div className="contact-left">
        <h3 className="contact-left__text">CONTACT US</h3>
      </div>
      <div className="contact-right">
        <form action="https://formspree.io/f/xwkwbaaq" method="POST" className="contact-form">
          <label htmlFor="name">Name</label>
          <input id="name" name="name" placeholder="First Last" className="contact-form__name" type="text" />
          <label htmlFor="email">Email*</label>
          <input id="email" name="email" placeholder="example@email.com" className="contact-form__email" type="email" required=""/>
          <label htmlFor="msg">Message*</label>
          <textarea id="msg" name="message" placeholder="Your message here..." className="contact-form__msg" type="text" required=""/>
          <input className="contact-form__submit" type="submit"></input>
        </form>
      </div>
    </section>

    <Footer />
  </div>
)

export default IndexPage
